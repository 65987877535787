var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.tags && _vm.tags.length)?_c('ul',{attrs:{"uk-accordion":""}},[(_vm.tags && _vm.tags.length)?_c('li',{staticClass:"uk-width-1-1"},[_c('a',{staticClass:"\n        uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom\n        c-resources-grants-filters-title\n      ",attrs:{"href":"#"}},[_vm._v(" Filter by Tag ")]),_c('ul',{staticClass:"uk-accordion-content uk-nav-default uk-nav-parent-icon",attrs:{"uk-nav":"multiple: true"}},_vm._l((_vm.tags),function(tag){return _c('li',{key:tag.id,staticClass:"uk-nav-sub uk-margin-small"},[_c('div',{staticClass:"uk-grid-small",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('a',{class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedTagIds.includes(tag.id),
                'uk-text-bold': _vm.selectedTagIds.includes(tag.id),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleTag(tag.id)}}},[_vm._v(_vm._s(tag.title))])]),_c('div',{staticClass:"uk-width-auto"},[(_vm.selectedTagIds.includes(tag.id))?_c('a',{staticClass:"far fa-times-circle",class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedTagIds.includes(tag.id),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleTag(tag.id)}}}):_vm._e()])])])}),0)]):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }