export default {
  install(Vue) {
    Vue.prototype.$decodeHtml = function (html) {
      // stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      txt.innerHTML = txt.value;
      return txt.value;
    };
    Vue.prototype.$smoothScrollToElement = function (element) {
      let scrollToElement = document.querySelector(element);
      scrollToElement.scrollIntoView({ behavior: "smooth", block: "start" });
    };
  },
};
