<template>
  <div
    class="
      uk-child-width-1-1
      uk-child-width-1-3@s
      uk-child-width-1-4@m
      uk-light
      uk-flex-center
      uk-text-center
      uk-grid-small
    "
    uk-grid
  >
    <div
      v-for="item in component"
      :key="item.id"
      class="c-image-overlay-button"
      v-show="showItemIds ? showItemIds.includes(item.id) : true"
    >
      <a
        :href="item.url ? item.url : '#'"
        @click.prevent="showItem(item)"
        class="
          uk-background-cover
          uk-height-small
          uk-panel
          uk-flex
          uk-flex-center
          uk-flex-middle
        "
        :style="{
          backgroundImage: item.thumbUrl ? `url(${item.thumbUrl})` : 'none',
        }"
      >
        <div
          class="uk-overlay-default uk-position-cover"
          :class="{
            'c-overlay-selected': item.id == selectedItemId,
          }"
        ></div>
        <div class="uk-overlay uk-position-center uk-light uk-padding-small">
          <p
            class="
              uk-h3 uk-text
              text-small-medium
              uk-text-uppercase
              text-pankow-blue-shadow
            "
          >
            {{
              item.buttonText
                ? $decodeHtml(item.buttonText)
                : $decodeHtml(item.title)
            }}
          </p>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageComponentImageOverlayButtons",
  props: [
    "component", // component: Array of items which include id, thumbUrl, and buttonText || title
    "selectedItemId", // selectedItemId: set to highlight
    "showItemIds", // showItemIds: (option) list of ids; used to filter from component
  ],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    showItem(itemId) {
      this.$emit("showItem", itemId);
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";
.uk-overlay-default {
  background: @color-pankow-blue-40;
}
.c-image-overlay-button:hover .uk-overlay-default,
.c-overlay-selected {
  background: @color-pankow-blue-80;
}
</style>
