<template>
  <div>
    <div
      class="
        uk-text uk-text-bold uk-margin-small-bottom
        c-resources-grants-filter-categories-title
      "
    >
      Categories
    </div>
    <div class="uk-width-1-1" v-if="showCategoryIds">
      <ul class="uk-nav-default uk-nav-parent-icon" uk-nav="multiple: true">
        <li>
          <a
            href="#display-items"
            uk-scroll="offset:220"
            @click="clearCategory()"
            :class="{
              'c-resources-grants-filter-item-selected': !selectedCategory,
              'uk-text-bold': !selectedCategory,
            }"
            >All</a
          >
        </li>
        <li
          class="uk-parent"
          v-for="item in categories"
          :key="item.id"
          v-show="showCategoryIds.includes(item.id)"
        >
          <a
            href="#display-items"
            uk-scroll="offset:220"
            @click="toggleCategory(item)"
            :class="{
              'c-resources-grants-filter-item-selected':
                selectedCategory && selectedCategory.id == item.id,
              'uk-text-bold':
                selectedCategory && selectedCategory.id == item.id,
            }"
            >{{ item.title }}</a
          >
          <ul class="uk-nav-sub" v-if="item.children && item.children.length">
            <app-page-component-resources-browser-filter-sub-nav-items
              v-for="category in item.children"
              :key="category.id"
              :childCategory="category"
              :showCategoryIds="showCategoryIds"
              :selectedCategory="selectedCategory"
              v-on="$listeners"
            ></app-page-component-resources-browser-filter-sub-nav-items>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PageComponentResourcesBrowserFilterSubNavItems from "@/components/PageComponentResourcesBrowserFilterSubNavItems";

export default {
  name: "PageComponentResourcesBrowserFilterCategories",
  props: ["categories", "showCategoryIds", "selectedCategory"],
  data() {
    return {};
  },
  computed: {},
  components: {
    appPageComponentResourcesBrowserFilterSubNavItems:
      PageComponentResourcesBrowserFilterSubNavItems,
  },
  methods: {
    toggleCategory(category) {
      this.$emit("toggleCategory", category);
    },
    clearCategory() {
      this.$emit("clearCategory");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}

@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}
</style>
