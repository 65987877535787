<template>
  <ul uk-accordion v-if="tags && tags.length">
    <li class="uk-width-1-1" v-if="tags && tags.length">
      <a
        href="#"
        class="
          uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom
          c-resources-grants-filters-title
        "
      >
        Filter by Tag
      </a>
      <ul
        class="uk-accordion-content uk-nav-default uk-nav-parent-icon"
        uk-nav="multiple: true"
      >
        <li
          class="uk-nav-sub uk-margin-small"
          v-for="tag in tags"
          :key="tag.id"
        >
          <div uk-grid class="uk-grid-small">
            <div class="uk-width-expand">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedTagIds.includes(tag.id),
                  'uk-text-bold': selectedTagIds.includes(tag.id),
                }"
                @click="toggleTag(tag.id)"
                >{{ tag.title }}</a
              >
            </div>
            <div class="uk-width-auto">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedTagIds.includes(tag.id),
                }"
                @click="toggleTag(tag.id)"
                v-if="selectedTagIds.includes(tag.id)"
                class="far fa-times-circle"
              ></a>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PageComponentResourcesBrowserFilterTags",
  props: ["tags", "selectedTagIds"],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    toggleTag(tagId) {
      this.$emit("toggleTagId", tagId);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}

@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}

a:hover {
  text-decoration: none;
}
</style>
