var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.types && _vm.types.length)?_c('ul',{attrs:{"uk-accordion":""}},[_c('li',{staticClass:"uk-width-1-1"},[_c('a',{staticClass:"\n        uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom\n        c-resources-grants-filters-title\n      ",attrs:{"href":"#"}},[_vm._v(" Filter by Type ")]),_c('ul',{staticClass:"uk-accordion-content uk-nav-default uk-nav-parent-icon",attrs:{"uk-nav":"multiple: true"}},_vm._l((_vm.types),function(type,index){return _c('li',{key:'type-' + index,staticClass:"uk-nav-sub uk-margin-small"},[_c('div',{staticClass:"uk-grid-small",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('a',{class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedTypes && _vm.selectedTypes.includes(type),
                'uk-text-bold': _vm.selectedTypes && _vm.selectedTypes.includes(type),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleType(type)}}},[_vm._v(_vm._s(type))])]),_c('div',{staticClass:"uk-width-auto"},[(_vm.selectedTypes && _vm.selectedTypes.includes(type))?_c('a',{staticClass:"far fa-times-circle",class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedTypes && _vm.selectedTypes.includes(type),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleType(type)}}}):_vm._e()])])])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }