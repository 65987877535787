var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.grantees && _vm.grantees.length)?_c('ul',{staticClass:"uk-margin-large-bottom",attrs:{"uk-accordion":""}},[_c('li',{staticClass:"uk-width-1-1"},[_c('a',{staticClass:"\n        uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom\n        c-resources-grants-filters-title\n      ",attrs:{"href":"#"}},[_vm._v(" Filter by Grantee ")]),_c('ul',{staticClass:"uk-accordion-content uk-nav-default uk-nav-parent-icon",attrs:{"uk-nav":"multiple: true"}},_vm._l((_vm.grantees),function(grantee,index){return _c('li',{key:'grantee-' + index,staticClass:"uk-nav-sub uk-margin-small"},[_c('div',{staticClass:"uk-grid-small",attrs:{"uk-grid":""}},[_c('div',{staticClass:"uk-width-expand"},[_c('a',{class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedGrantees && _vm.selectedGrantees.includes(grantee),
                'uk-text-bold':
                  _vm.selectedGrantees && _vm.selectedGrantees.includes(grantee),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleGrantee(grantee)}}},[_vm._v(_vm._s(grantee))])]),_c('div',{staticClass:"uk-width-auto"},[(_vm.selectedGrantees && _vm.selectedGrantees.includes(grantee))?_c('a',{staticClass:"far fa-times-circle",class:{
                'c-resources-grants-filter-item-selected':
                  _vm.selectedGrantees && _vm.selectedGrantees.includes(grantee),
              },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleGrantee(grantee)}}}):_vm._e()])])])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }