import { render, staticRenderFns } from "./PageComponentImageOverlayButtons.vue?vue&type=template&id=f12b34ba&scoped=true&"
import script from "./PageComponentImageOverlayButtons.vue?vue&type=script&lang=js&"
export * from "./PageComponentImageOverlayButtons.vue?vue&type=script&lang=js&"
import style0 from "./PageComponentImageOverlayButtons.vue?vue&type=style&index=0&id=f12b34ba&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f12b34ba",
  null
  
)

export default component.exports