<template>
  <ul
    uk-accordion
    class="uk-margin-large-bottom"
    v-if="grantees && grantees.length"
  >
    <li class="uk-width-1-1">
      <a
        href="#"
        class="
          uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom
          c-resources-grants-filters-title
        "
      >
        Filter by Grantee
      </a>
      <ul
        class="uk-accordion-content uk-nav-default uk-nav-parent-icon"
        uk-nav="multiple: true"
      >
        <li
          class="uk-nav-sub uk-margin-small"
          v-for="(grantee, index) in grantees"
          :key="'grantee-' + index"
        >
          <div uk-grid class="uk-grid-small">
            <div class="uk-width-expand">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedGrantees && selectedGrantees.includes(grantee),
                  'uk-text-bold':
                    selectedGrantees && selectedGrantees.includes(grantee),
                }"
                @click="toggleGrantee(grantee)"
                >{{ grantee }}</a
              >
            </div>
            <div class="uk-width-auto">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedGrantees && selectedGrantees.includes(grantee),
                }"
                @click="toggleGrantee(grantee)"
                v-if="selectedGrantees && selectedGrantees.includes(grantee)"
                class="far fa-times-circle"
              ></a>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PageComponentResourcesBrowserFilterGrantee",
  props: ["grantees", "selectedGrantees"],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    toggleGrantee(grantee) {
      this.$emit("toggleGrantee", grantee);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}

@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}

a:hover {
  text-decoration: none;
}
</style>
