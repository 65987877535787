<template>
  <ul class="uk-list uk-list-divider">
    <li v-show="items.length == 0">
      <div class="uk-margin-small-bottom">No results found.</div>
      <div class="uk-margin-small-bottom">
        <a href="#" v-show="hasSelectedFilters" @click.prevent="clearFilters()"
          >Clear filters?</a
        >
      </div>
      <div>
        <a
          href="#"
          v-show="hasSelectedCategory"
          @click.prevent="clearCategory()"
          >Clear category?</a
        >
      </div>
    </li>
    <transition name="fade" v-for="item in items" :key="item.id">
      <li>
        <div uk-grid class="uk-grid uk-grid-small">
          <div v-if="item.type !== 'Grant'" style="width: 32px">
            <span
              v-if="item.mediaType == 'link'"
              class="fas fa-external-link-alt fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'pdf'"
              class="far fa-file-pdf fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'video'"
              class="fas fa-film fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'case-study'"
              class="fas fa-search fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'spreadsheet'"
              class="far fa-file-excel fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'document'"
              class="far fa-file-word fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'zip'"
              class="far fa-file-archive fa-2x"
            ></span>
            <span
              v-if="item.mediaType == 'ppt'"
              class="far fa-file-powerpoint fa-2x"
            ></span>
          </div>
          <div class="uk-width-expand">
            <div class="uk-text-light uk-margin-small-bottom">
              <a
                :href="itemUrl(item)"
                target="_blank"
                class="uk-text-large"
                v-if="item.title"
                >{{
                  item.type == "Grant"
                    ? $decodeHtml(item.heroTitle)
                    : $decodeHtml(item.title)
                }}</a
              >
              <div
                class="uk-margin-small-top"
                v-if="item.type !== 'Grant' && item.project"
              >
                {{ $decodeHtml(item.project) }}
              </div>
            </div>
            <div
              class="uk-text-bold uk-margin-small-bottom"
              v-if="item.grantNum"
            >
              Grant # {{ item.grantNum }}
            </div>
            <div
              v-if="item.description"
              v-html="$decodeHtml($decodeHtml(item.description))"
            ></div>
            <div class="uk-margin-top" v-if="item.tags && item.tags.length">
              <a
                v-for="tag in sortByTitle(item.tags)"
                :key="tag.id"
                class="uk-margin-right"
                @click.prevent="toggleTag(tag.id)"
                >{{ tag.title }}</a
              >
            </div>
          </div>
        </div>
      </li>
    </transition>
  </ul>
</template>

<script>
import { orderBy } from "lodash";

export default {
  name: "PageComponentResourcesBrowserDisplayItems",
  props: [
    "items",
    "selectedTagIds",
    "selectedCategory",
    "selectedTypes",
    "hasSelectedFilters",
    "hasSelectedCategory",
  ],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    sortByTitle(array) {
      return orderBy(array, "title");
    },
    toggleTag(tagId) {
      this.$emit("toggleTagId", tagId);
    },
    clearFilters() {
      this.$emit("clearFilters");
    },
    clearCategory() {
      this.$emit("clearCategory");
    },
    itemUrl(item) {
      if (item.url) {
        switch (item.type) {
          case "Case Study":
            return item.url + "#case-study";
          case "Grant":
            return item.url;
          default:
            return item.url + "#resources";
        }
      }
      return "#";
    },
  },
};
</script>

<style lang="less" scoped>
// @import "../styles/variables.less";
a {
  text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
