var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      uk-text uk-text-bold uk-margin-small-bottom\n      c-resources-grants-filter-categories-title\n    "},[_vm._v(" Categories ")]),(_vm.showCategoryIds)?_c('div',{staticClass:"uk-width-1-1"},[_c('ul',{staticClass:"uk-nav-default uk-nav-parent-icon",attrs:{"uk-nav":"multiple: true"}},[_c('li',[_c('a',{class:{
            'c-resources-grants-filter-item-selected': !_vm.selectedCategory,
            'uk-text-bold': !_vm.selectedCategory,
          },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.clearCategory()}}},[_vm._v("All")])]),_vm._l((_vm.categories),function(item){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCategoryIds.includes(item.id)),expression:"showCategoryIds.includes(item.id)"}],key:item.id,staticClass:"uk-parent"},[_c('a',{class:{
            'c-resources-grants-filter-item-selected':
              _vm.selectedCategory && _vm.selectedCategory.id == item.id,
            'uk-text-bold':
              _vm.selectedCategory && _vm.selectedCategory.id == item.id,
          },attrs:{"href":"#display-items","uk-scroll":"offset:220"},on:{"click":function($event){return _vm.toggleCategory(item)}}},[_vm._v(_vm._s(item.title))]),(item.children && item.children.length)?_c('ul',{staticClass:"uk-nav-sub"},_vm._l((item.children),function(category){return _c('app-page-component-resources-browser-filter-sub-nav-items',_vm._g({key:category.id,attrs:{"childCategory":category,"showCategoryIds":_vm.showCategoryIds,"selectedCategory":_vm.selectedCategory}},_vm.$listeners))}),1):_vm._e()])})],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }