<template>
  <div class="uk-container uk-margin-top">
    <app-page-component-image-overlay-buttons
      :component="component.caseStudies"
      :selectedItemId="selectedCaseStudy ? selectedCaseStudy.id : 0"
      @showItem="showItem"
    ></app-page-component-image-overlay-buttons>

    <div class="uk-margin uk-margin-large-top">
      <div
        v-if="selectedCaseStudy"
        id="case-study"
        class="scroll-header-offset"
      >
        <h2 class="uk-margin-small-bottom text-medium">
          {{ $decodeHtml(selectedCaseStudy.title) }}
        </h2>
        <h4 class="uk-margin-small-top">
          {{ selectedCaseStudy.description }}
        </h4>
        <section class="uk-margin-large-bottom uk-margin-top">
          <iframe
            height="800px"
            width="100%"
            :src="
              '/pdfjs-2.9.359-dist/web/viewer.html?file=' +
              selectedCaseStudy.pdfUrl
            "
          ></iframe>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PageComponentImageOverlayButtons from "@/components/PageComponentImageOverlayButtons";

export default {
  name: "PageComponentCaseStudies",
  props: ["component"],
  data() {
    return {
      selectedCaseStudy: null,
    };
  },
  computed: {},
  components: {
    appPageComponentImageOverlayButtons: PageComponentImageOverlayButtons,
  },
  methods: {
    showItem(item) {
      if (item.url) {
        window.open(item.url + "#case-study", "_self");
      }
    },
  },
  mounted() {
    if (this.component && this.component.caseStudies) {
      if (this.component.currentPage) {
        for (let caseStudy of this.component.caseStudies) {
          if (caseStudy.id === this.component.currentPage) {
            this.selectedCaseStudy = caseStudy;
          }
        }
      }
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
// @import "../styles/variables.less";
</style>
