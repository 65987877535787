import Vue from "vue";
import App from "./App.vue";
import PageComponentAccordion from "./components/PageComponentAccordion";
import PageComponentCaseStudies from "./components/PageComponentCaseStudies";
import PageComponentLeaders from "./components/PageComponentLeaders";
import PageComponentResourcesBrowser from "./components/PageComponentResourcesBrowser";
import Util from "./plugins/util";

Vue.config.productionTip = false;
Vue.use(Util);


// new Vue({
//   render: (h) => h(App),
// }).$mount("#app");

new Vue({
  el: "#app",
  components: {
    App,
    PageComponentAccordion,
    PageComponentLeaders,
    PageComponentCaseStudies,
    PageComponentResourcesBrowser,
  },
});
