<template>
  <ul uk-accordion v-if="statuses && statuses.length">
    <li class="uk-width-1-1">
      <a
        href="#"
        class="
          uk-accordion-title uk-text uk-text-bold uk-margin-small-bottom
          c-resources-grants-filters-title
        "
      >
        Filter by Status
      </a>
      <ul
        class="uk-accordion-content uk-nav-default uk-nav-parent-icon"
        uk-nav="multiple: true"
      >
        <li
          class="uk-nav-sub uk-margin-small"
          v-for="(status, index) in statuses"
          :key="'status-' + index"
        >
          <div uk-grid class="uk-grid-small">
            <div class="uk-width-expand">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedStatuses && selectedStatuses.includes(status),
                  'uk-text-bold':
                    selectedStatuses && selectedStatuses.includes(status),
                }"
                @click="toggleStatus(status)"
                >{{ status }}</a
              >
            </div>
            <div class="uk-width-auto">
              <a
                href="#display-items"
                uk-scroll="offset:220"
                :class="{
                  'c-resources-grants-filter-item-selected':
                    selectedStatuses && selectedStatuses.includes(status),
                }"
                @click="toggleStatus(status)"
                v-if="selectedStatuses && selectedStatuses.includes(status)"
                class="far fa-times-circle"
              ></a>
            </div>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: "PageComponentResourcesBrowserFilterStatus",
  props: ["statuses", "selectedStatuses"],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    toggleStatus(status) {
      this.$emit("toggleStatus", status);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}

@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}

a:hover {
  text-decoration: none;
}
</style>
