<template>
  <div></div>
  <!-- <div id="app">
    <img alt="Vue logo" src="./assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js App" />
  </div> -->
</template>

<script>
export default {
  name: "App",
  components: {},
};
</script>

<style lang="less">
@import "./styles/app.less";
</style>
