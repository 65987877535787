<template>
  <section id="resources" class="uk-margin-large-bottom">
    <app-page-component-image-overlay-buttons
      class="uk-margin-large-bottom uk-visible@s"
      :component="component.grantCategories"
      :selectedItemId="selectedCategory ? selectedCategory.id : null"
      :showItemIds="filterCategories"
      @showItem="toggleCategoryFromOverlayButton"
    ></app-page-component-image-overlay-buttons>
    <!-- Filters -->
    <div class="uk-margin-large-bottom uk-width-1-1 uk-inline uk-hidden@s">
      <button
        class="uk-button uk-button-primary uk-position-center"
        type="button"
        uk-toggle="target: #offcanvas-reveal"
      >
        <span>Filter</span
        ><span class="uk-margin-small-left fas fa-filter"></span>
      </button>
      <!-- Mobile filter menu -->
      <div id="offcanvas-reveal" uk-offcanvas="mode: reveal; overlay: true">
        <div class="uk-offcanvas-bar">
          <button class="uk-offcanvas-close" type="button" uk-close></button>
          <app-page-component-resources-browser-filter
            class="uk-margin-large-top"
            :categories="component.grantCategories"
            :tags="filterTags"
            :showCategoryIds="filterCategories"
            :selectedCategory="selectedCategory"
            :selectedTagIds="selectedTagIds"
            :types="filterTypes"
            :selectedTypes="selectedTypes"
            :statuses="filterStatuses"
            :selectedStatuses="selectedStatuses"
            :grantees="filterGrantees"
            :selectedGrantees="selectedGrantees"
            :searchQuery="searchQuery"
            @toggleTagId="toggleTagId"
            @toggleType="toggleType"
            @toggleStatus="toggleStatus"
            @toggleGrantee="toggleGrantee"
            @toggleCategory="toggleCategory"
            @clearCategory="clearCategory"
            @clearFilters="clearFilters"
          ></app-page-component-resources-browser-filter>
        </div>
      </div>
    </div>
    <div uk-grid class="uk-grid-large">
      <!-- Desktop filter menu -->
      <div class="uk-width-2-5 uk-width-1-3@m uk-visible@s">
        <app-page-component-resources-browser-filter
          uk-sticky="offset: 120; bottom: true"
          class="display-items-container"
          :categories="component.grantCategories"
          :tags="filterTags"
          :showCategoryIds="filterCategories"
          :selectedCategory="selectedCategory"
          :selectedTagIds="selectedTagIds"
          :types="filterTypes"
          :selectedTypes="selectedTypes"
          :statuses="filterStatuses"
          :selectedStatuses="selectedStatuses"
          :grantees="filterGrantees"
          :selectedGrantees="selectedGrantees"
          :searchQuery="searchQuery"
          @toggleTagId="toggleTagId"
          @toggleType="toggleType"
          @toggleStatus="toggleStatus"
          @toggleGrantee="toggleGrantee"
          @toggleCategory="toggleCategory"
          @clearCategory="clearCategory"
          @clearFilters="clearFilters"
        ></app-page-component-resources-browser-filter>
      </div>
      <!-- Display Items -->
      <div class="uk-width-1-1 uk-width-expand@s">
        <div uk-grid>
          <!-- Search -->
          <div class="uk-width-expand">
            <div class="uk-search uk-search-large uk-width-1-1">
              <input
                v-model="searchInput"
                class="uk-search-input"
                type="search"
                placeholder="Search..."
                :disabled="isLoading"
              />
            </div>
          </div>

          <div class="uk-width-auto uk-inline">
            <a
              v-show="searchQuery"
              class="uk-position-center far fa-times-circle fa-2x"
              href="#"
              @click.prevent="clearSearch"
            >
            </a>
          </div>
        </div>
        <div
          uk-grid
          v-if="selectedCategory"
          class="uk-margin-bottom uk-margin-remove-top"
        >
          <div class="uk-width-expand uk-text-large">
            <span class="uk-text-bold">Category:</span
            ><span
              class="uk-margin-small-left uk-text-light text-pankow-blue"
              >{{ selectedCategory.title }}</span
            >
          </div>
          <div class="uk-width-auto uk-inline">
            <a
              class="uk-position-center far fa-times-circle fa-2x"
              href="#"
              @click.prevent="clearCategory"
            >
            </a>
          </div>
        </div>
        <div id="display-items">
          <div v-if="isLoading" class="uk-width-1-1 uk-text-center">
            <div uk-spinner="ratio: 2" class="text-pankow-blue"></div>
            <div class="uk-margin-small-top uk-text-bold text-pankow-blue">
              Searching...
            </div>
          </div>

          <app-page-component-resources-browser-display-items
            v-show="!isLoading"
            :items="sortedFilteredItems"
            :selectedTagIds="selectedTagIds"
            :selectedCategory="selectedCategory"
            :selectedTypes="selectedTypes"
            :hasSelectedFilters="hasSelectedFilters"
            :hasSelectedCategory="hasSelectedCategory"
            @toggleTagId="toggleTagId"
            @clearFilters="clearFilters"
            @clearCategory="clearCategory"
          ></app-page-component-resources-browser-display-items>
          <div
            class="uk-width-1-1 uk-margin-large-top uk-inline"
            v-show="
              !isLoading &&
              sortedFilteredItems &&
              sortedFilteredItems.length > 10
            "
          >
            <a href="#resources" uk-totop uk-scroll class="uk-position-center"
              >Back to Top
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { debounce } from "lodash";
import $ from "jquery";
import PageComponentImageOverlayButtons from "@/components/PageComponentImageOverlayButtons";
import PageComponentResourcesBrowserFilter from "@/components/PageComponentResourcesBrowserFilter";
import PageComponentResourcesBrowserDisplayItems from "@/components/PageComponentResourcesBrowserDisplayItems";

export default {
  name: "PageComponentResourcesBrowser",
  props: ["component"],
  data() {
    return {
      isLoading: false,
      ajaxComponent: null,
      showCaseStudies: this.component.showCaseStudies
        ? this.component.showCaseStudies
        : false,
      showResources: this.component.showResources
        ? this.component.showResources
        : false,
      showGrants: this.component.showGrants ? this.component.showGrants : false,
      searchInput: "",
      searchQuery: "",
      filterCategories: [],
      filterTags: [],
      filterTypes: [],
      filterStatuses: [],
      filterGrantees: [],
      selectedTagIds: [],
      selectedTypes: [],
      selectedStatuses: [],
      selectedGrantees: [],
      selectedCategory: null,
      displayItems: [],
    };
  },
  computed: {
    hasSelectedFilters() {
      if (
        this.selectedTagIds.length ||
        this.selectedTypes.length ||
        this.selectedStatuses.length ||
        this.selectedGrantees.length
      )
        return true;
      return false;
    },
    hasSelectedCategory() {
      if (this.selectedCategory) return true;
      return false;
    },
    sortedFilteredItems() {
      let sorted = [...this.displayItems];

      // Filter by category
      if (this.selectedCategory) {
        sorted = sorted.filter((item) =>
          this.itemContainsCategory(item, this.selectedCategory)
        );
      }

      // Filter by tags
      if (this.selectedTagIds && this.selectedTagIds.length) {
        sorted = sorted.filter((item) =>
          this.itemContainsTag(item, this.selectedTagIds)
        );
      }

      // Filter by types
      if (this.selectedTypes && this.selectedTypes.length) {
        sorted = sorted.filter((item) =>
          item.type ? this.selectedTypes.includes(item.type) : false
        );
      }

      // Filter by statuses
      if (this.selectedStatuses && this.selectedStatuses.length) {
        sorted = sorted.filter((item) =>
          item.status ? this.selectedStatuses.includes(item.status) : false
        );
      }

      // Filter by grantees
      if (this.selectedGrantees && this.selectedGrantees.length) {
        sorted = sorted.filter((item) =>
          item.grantee ? this.selectedGrantees.includes(item.grantee) : false
        );
      }

      return sorted.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
  },
  components: {
    appPageComponentImageOverlayButtons: PageComponentImageOverlayButtons,
    appPageComponentResourcesBrowserFilter: PageComponentResourcesBrowserFilter,
    appPageComponentResourcesBrowserDisplayItems:
      PageComponentResourcesBrowserDisplayItems,
  },
  methods: {
    getAjaxComponent() {
      this.isLoading = true;
      $.ajax({
        type: "GET",
        data: { searchInput: this.searchQuery },
        url: window.location.href,
        success: function (data) {
          this.ajaxComponent = data;
          this.initData();
          this.isLoading = false;
        }.bind(this),
        error: function () {
          console.log("Error is retrieving search data.");
          this.isLoading = false;
        },
      });
    },
    initData() {
      this.displayItems = [];

      const browserData = this.ajaxComponent
        ? this.ajaxComponent
        : this.component;

      // Get Grants
      if (this.showGrants && browserData.grants) {
        this.displayItems = [...this.displayItems, ...browserData.grants];
      }

      // Get Resource Items from Grants
      if (this.showResources && browserData.grants) {
        for (let grant of browserData.grants) {
          if (grant.resources) {
            let item = [...grant.resources];
            for (let resourceItem of item) {
              resourceItem.categories = grant.categories;
              resourceItem.url = grant.url;
              resourceItem.project = grant.project;
              resourceItem.grantNum = grant.grantNum;
            }
            this.displayItems = [...this.displayItems, ...item];
          }
        }
      }
      // Add Case Studies
      if (this.showCaseStudies && browserData.caseStudies) {
        this.displayItems = [...this.displayItems, ...browserData.caseStudies];
      }
      // Add Non-grant resources
      if (this.showResources && browserData.resources) {
        for (let resource of browserData.resources) {
          if (resource.resources) {
            let item = [...resource.resources];
            for (let resourceItem of item) {
              resourceItem.categories = resource.categories
                ? resource.categories
                : [];
              resourceItem.url = resource.url;
              resourceItem.project = "";
            }
            this.displayItems = [...this.displayItems, ...item];
          }
        }
      }
    },
    initFilters() {
      // Categories
      this.filterCategories = [];
      if (this.displayItems) {
        for (let item of this.displayItems) {
          if (item.categories && item.categories.length) {
            for (let category of item.categories) {
              if (!this.filterCategories.includes(category.id)) {
                this.filterCategories.push(category.id);
              }
            }
          }
        }
      }

      // Tags
      this.filterTags = [];
      if (this.displayItems) {
        for (let item of this.displayItems) {
          if (item.tags && item.tags.length) {
            for (let tag of item.tags) {
              if (
                this.filterTags.findIndex(
                  (tagInresult) => tagInresult.id === tag.id
                ) < 0
              ) {
                this.filterTags.push(tag);
              }
            }
          }
        }
      }
      this.filterTags = this.filterTags.sort((a, b) =>
        a.title > b.title ? 1 : -1
      );

      // Types
      this.filterTypes = [];
      if (this.displayItems) {
        for (let item of this.displayItems) {
          if (item.type) {
            if (!this.filterTypes.includes(item.type)) {
              this.filterTypes.push(item.type);
            }
          }
        }
      }
      // Remove "Grant" tag if present
      if (this.filterTypes.includes("Grant")) {
        this.filterTypes.splice(this.filterTypes.indexOf("Grant"), 1);
      }
      this.filterTypes = this.filterTypes.sort();

      // Statuses
      this.filterStatuses = [];
      if (this.displayItems) {
        for (let item of this.displayItems) {
          if (item.status) {
            if (!this.filterStatuses.includes(item.status)) {
              this.filterStatuses.push(item.status);
            }
          }
        }
      }
      this.filterStatuses = this.filterStatuses.sort();

      // Grantees
      this.filterGrantees = [];
      if (this.displayItems) {
        for (let item of this.displayItems) {
          if (item.grantee) {
            if (!this.filterGrantees.includes(item.grantee)) {
              this.filterGrantees.push(item.grantee);
            }
          }
        }
      }
      this.filterGrantees = this.filterGrantees.sort();
    },
    toggleTagId(tagId) {
      this.selectedTagIds.includes(tagId)
        ? this.selectedTagIds.splice(this.selectedTagIds.indexOf(tagId), 1)
        : this.selectedTagIds.push(tagId);
    },
    toggleType(type) {
      this.selectedTypes.includes(type)
        ? this.selectedTypes.splice(this.selectedTypes.indexOf(type), 1)
        : this.selectedTypes.push(type);
    },
    toggleStatus(status) {
      this.selectedStatuses.includes(status)
        ? this.selectedStatuses.splice(this.selectedStatuses.indexOf(status), 1)
        : this.selectedStatuses.push(status);
    },
    toggleGrantee(grantee) {
      this.selectedGrantees.includes(grantee)
        ? this.selectedGrantees.splice(
            this.selectedGrantees.indexOf(grantee),
            1
          )
        : this.selectedGrantees.push(grantee);
    },
    toggleCategory(category) {
      this.selectedCategory && this.selectedCategory.id == category.id
        ? (this.selectedCategory = null)
        : (this.selectedCategory = category);
    },
    toggleCategoryFromOverlayButton(category) {
      this.toggleCategory(category);
      this.$smoothScrollToElement("#resources");
    },
    clearFilters() {
      this.selectedTagIds = [];
      this.selectedTypes = [];
      this.selectedStatuses = [];
      this.selectedGrantees = [];
    },
    clearSearch() {
      this.searchInput = "";
      this.searchQuery = "";
      if (history) {
        history.pushState(null, "", location.href.split("?")[0]);
      } else {
        location.hash = "#/";
      }
    },
    clearCategory() {
      this.selectedCategory = null;
    },
    itemContainsCategory(item, selectedCategory) {
      if (!selectedCategory) return true;
      if (!item.categories) return false;
      for (let category of item.categories) {
        if (this.selectedCategory.id == category.id) return true;
      }
      return false;
    },
    itemContainsTag(item, tagIds) {
      if (!tagIds || !tagIds.length) return true;
      if (item.tags && item.tags.length) {
        for (let tag of item.tags) {
          if (tagIds.includes(tag.id)) return true;
        }
      }
      return false;
    },
  },
  mounted() {
    this.initData();
    this.initFilters();
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has("search")) {
      this.searchInput = searchParams.get("search");
      this.$smoothScrollToElement("#resources");
    }
  },
  watch: {
    searchInput: debounce(function (newVal) {
      this.searchQuery = newVal;
      if (this.searchQuery && this.searchQuery !== "") {
        this.getAjaxComponent();
      } else {
        this.ajaxComponent = null;
        this.initData();
      }
    }, 1000),
  },
};
</script>

<style lang="less" scoped>
// @import "../styles/variables.less";

a:hover {
  text-decoration: none;
}

.display-items-container {
  height: 80vh;
  overflow-y: auto;
}

.uk-search-large .uk-search-input {
  font-size: 2rem;
}
</style>
