<template>
  <section
    class="uk-padding uk-padding-remove-horizontal"
    :style="{ 'background-color': component.color }"
    :id="component.htmlId ? $decodeHtml(component.htmlId) : ''"
  >
    <div class="uk-container">
      <div
        v-if="component.content"
        v-html="$decodeHtml(component.content)"
      ></div>

      <ul
        uk-accordion="multiple: true"
        v-if="component.accordionItems.length > 0"
      >
        <li
          :class="{ 'uk-open': accordionItem.open }"
          v-for="accordionItem in component.accordionItems"
          :key="accordionItem.id"
          :id="accordionItem.htmlId ? $decodeHtml(accordionItem.htmlId) : ''"
        >
          <a class="uk-accordion-title" href="#"
            ><h3 class="uk-margin-remove text-medium">
              {{ $decodeHtml(accordionItem.title) }}
            </h3></a
          >
          <div class="uk-accordion-content">
            <p
              v-if="accordionItem.content"
              v-html="$decodeHtml(accordionItem.content)"
            ></p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageComponentAccordion",
  props: ["component"],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {},
};
</script>

<style lang="less" scoped>
// @import "../styles/variables.less";
</style>
