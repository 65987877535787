<template>
  <section
    class="uk-padding uk-padding-remove-horizontal uk-padding-remove-top"
    :style="{ 'background-color': component.color }"
  >
    <div class="uk-container uk-margin-large-bottom">
      <div uk-grid class="uk-grid-small" v-if="component.leaders.length > 0">
        <div
          v-for="leader in component.leaders"
          :key="leader.id"
          class="uk-width-1-2 uk-width-1-3@s"
          uk-toggle="target: #modal-leader"
          @click="selected = leader"
        >
          <div
            class="uk-card uk-card-small uk-card-body uk-visible-toggle uk-link"
          >
            <div v-if="leader.imgUrl">
              <div class="uk-inline">
                <img
                  class="c-leaders-img"
                  :src="leader.imgUrl"
                  :alt="leader.imgDescription ? leader.imgDescription : ''"
                />
                <div
                  class="
                    uk-overlay-primary uk-position-cover uk-invisible-hover
                    overlay-pankow-blue
                  "
                ></div>
                <div
                  class="
                    uk-overlay uk-position-center uk-light uk-invisible-hover
                  "
                >
                  <div
                    class="uk-text uk-text-large uk-text-bold"
                    style="color: #ffffff"
                  >
                    VIEW
                  </div>
                </div>
              </div>
            </div>
            <div
              class="
                uk-text uk-text-bold uk-text-small
                text-pankow-blue
                uk-margin-small-top
              "
            >
              <span class="uk-text-uppercase" v-if="leader.name">{{
                $decodeHtml(leader.name)
              }}</span
              ><span v-if="leader.title"
                >, {{ $decodeHtml(leader.title) }}</span
              >
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div id="modal-leader" uk-modal>
        <div class="uk-modal-dialog uk-modal-body">
          <button
            class="uk-modal-close-default"
            type="button"
            uk-close
          ></button>
          <div uk-grid class="uk-grid uk-margin-top" v-if="selected">
            <div class="uk-width-1-2 uk-width-1-3@s">
              <div v-if="selected.imgUrl">
                <img
                  class="c-leaders-img"
                  :src="selected.imgUrl"
                  :alt="selected.imgDescription ? selected.imgDescription : ''"
                />
              </div>
            </div>
            <div class="uk-width-1-2 uk-width-2-3@s">
              <h3 class="uk-text uk-text-uppercase c-leader-modal-name">
                {{ $decodeHtml(selected.name) }}
              </h3>
            </div>
            <div class="uk-width-1-1">
              <div v-html="$decodeHtml(selected.content)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageComponentLeaders",
  props: ["component"],
  data() {
    return {
      selected: null,
    };
  },
  computed: {},
  components: {},
  methods: {},
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.c-leaders-img {
  width: 100%;
}
.c-leader-modal-name {
  @media (min-width: @breakpoint-small) {
    margin-top: 10px;
  }
  @media (min-width: @breakpoint-medium) {
    margin-top: 20px;
  }
}
</style>
