<template>
  <div>
    <!-- Category Filter -->
    <app-page-component-resources-browser-filter-categories
      v-if="categories && categories.length"
      :categories="categories"
      :showCategoryIds="showCategoryIds"
      :selectedCategory="selectedCategory"
      v-on="$listeners"
    ></app-page-component-resources-browser-filter-categories>

    <hr />

    <!-- Status Filter -->
    <app-page-component-resources-browser-filter-status
      v-if="statuses && statuses.length"
      class="uk-margin-top"
      :statuses="statuses"
      :selectedStatuses="selectedStatuses"
      v-on="$listeners"
    ></app-page-component-resources-browser-filter-status>

    <!-- Tags Filter -->
    <app-page-component-resources-browser-filter-tags
      v-if="tags && tags.length"
      class="uk-margin-top"
      :tags="tags"
      :selectedTagIds="selectedTagIds"
      v-on="$listeners"
    ></app-page-component-resources-browser-filter-tags>

    <!-- Types Filter -->
    <app-page-component-resources-browser-filter-types
      v-if="types && types.length"
      class="uk-margin-top"
      :types="types"
      :selectedTypes="selectedTypes"
      v-on="$listeners"
    ></app-page-component-resources-browser-filter-types>

    <!-- Grantee Filter -->
    <app-page-component-resources-browser-filter-grantee
      v-if="grantees && grantees.length"
      class="uk-margin-top"
      :grantees="grantees"
      :selectedGrantees="selectedGrantees"
      v-on="$listeners"
    ></app-page-component-resources-browser-filter-grantee>

    <button
      class="uk-margin-top uk-button uk-button-default"
      type="button"
      :disabled="!hasFilters"
      @click.prevent="clearFilters"
    >
      Clear Filters
    </button>
  </div>
</template>

<script>
import PageComponentResourcesBrowserFilterCategories from "@/components/PageComponentResourcesBrowserFilterCategories";
import PageComponentResourcesBrowserFilterGrantee from "@/components/PageComponentResourcesBrowserFilterGrantee";
import PageComponentResourcesBrowserFilterStatus from "@/components/PageComponentResourcesBrowserFilterStatus";
import PageComponentResourcesBrowserFilterTags from "@/components/PageComponentResourcesBrowserFilterTags";
import PageComponentResourcesBrowserFilterTypes from "@/components/PageComponentResourcesBrowserFilterTypes";

export default {
  name: "PageComponentResourcesBrowserFilter",
  props: [
    "categories",
    "tags",
    "showCategoryIds",
    "selectedTagIds",
    "selectedCategory",
    "types",
    "selectedTypes",
    "statuses",
    "selectedStatuses",
    "grantees",
    "selectedGrantees",
    "searchQuery",
  ],
  data() {
    return {};
  },
  computed: {
    hasFilters() {
      if (
        this.selectedTagIds.length ||
        this.selectedCategory != null ||
        this.selectedTypes.length ||
        this.selectedStatuses.length ||
        this.selectedGrantees.length
      )
        return true;
      return false;
    },
  },
  components: {
    appPageComponentResourcesBrowserFilterCategories:
      PageComponentResourcesBrowserFilterCategories,
    appPageComponentResourcesBrowserFilterGrantee:
      PageComponentResourcesBrowserFilterGrantee,
    appPageComponentResourcesBrowserFilterStatus:
      PageComponentResourcesBrowserFilterStatus,
    appPageComponentResourcesBrowserFilterTags:
      PageComponentResourcesBrowserFilterTags,
    appPageComponentResourcesBrowserFilterTypes:
      PageComponentResourcesBrowserFilterTypes,
  },
  methods: {
    clearFilters() {
      this.$emit("clearFilters");
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}

@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}

a:hover {
  text-decoration: none;
}
</style>
