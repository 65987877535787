<template>
  <li v-show="showCategoryIds.includes(childCategory.id)">
    <a
      href="#display-items"
      uk-scroll="offset:220"
      @click="toggleCategory(childCategory)"
      :class="{
        'c-resources-grants-filter-item-selected':
          selectedCategory && selectedCategory.id == childCategory.id,
        'uk-text-bold':
          selectedCategory && selectedCategory.id == childCategory.id,
      }"
      >{{ childCategory.title }}</a
    >
    <ul
      class="uk-nav-sub"
      v-if="childCategory.children && childCategory.children.length"
    >
      <page-component-resources-browser-filter-sub-nav-items
        v-for="subCategory in childCategory.children"
        :key="subCategory.id"
        :childCategory="subCategory"
        :showCategoryIds="showCategoryIds"
        :selectedCategory="selectedCategory"
        v-on="$listeners"
      ></page-component-resources-browser-filter-sub-nav-items>
    </ul>
  </li>
</template>

<script>
export default {
  name: "PageComponentResourcesBrowserFilterSubNavItems",
  props: ["childCategory", "showCategoryIds", "selectedCategory"],
  data() {
    return {};
  },
  computed: {},
  components: {},
  methods: {
    toggleCategory(category) {
      this.$emit("toggleCategory", category);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../styles/variables.less";

.uk-nav-default > li > a,
.uk-nav-default .uk-nav-sub a {
  color: @color-white;
}
@media (min-width: @breakpoint-small) {
  .uk-nav-default > li > a,
  .uk-nav-default .uk-nav-sub a {
    color: @color-black;
  }
}
</style>
